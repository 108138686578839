@font-face {
    font-family: 'Object Sans';
    src: url('../fonts/ObjectSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Object Sans';
    src: url('../fonts/ObjectSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Object Sans';
    src: url('../fonts/ObjectSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
//$font-family-monospace: 'Roboto Mono', monospace;
$font-family-monospace: 'Roboto Flex', monospace;
$font-family-base: 'Object Sans', normal;
$font-family-flex: 'Roboto Flex', sans-serif;
$font-family-code: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', 'Ubuntu', monospace;
//$enable-responsive-font-sizes: true;
