.react-datepicker {
    @extend .border-primary-light;
    @extend .shadow-sm;
}

.react-datepicker__header {
    background: none;
    border-bottom: none;
}

.react-datepicker__day-names {
    margin-bottom: -1.2rem;
}

.react-datepicker__day-name {
    color: $gray-500;
}

.react-datepicker__day--selected:not([aria-disabled=true]):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__day--in-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-range:not([aria-disabled=true]):hover {
    background-color: $primary;
    color: #fff;
}

@include color-mode(dark) {
    .react-datepicker {
        @extend .border-primary-light;
        @extend .bg-dark;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
        color: $light;
    }

    .react-datepicker__day:not([aria-disabled=true]):hover,
    .react-datepicker__month-text:not([aria-disabled=true]):hover,
    .react-datepicker__quarter-text:not([aria-disabled=true]):hover,
    .react-datepicker__year-text:not([aria-disabled=true]):hover {
        background-color: $primary-bg-subtle-dark;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: $primary-bg-subtle-dark;
    }

    .react-datepicker__day--selected:not([aria-disabled=true]):hover,
    .react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover,
    .react-datepicker__day--in-range:not([aria-disabled=true]):hover,
    .react-datepicker__month-text--selected:not([aria-disabled=true]):hover,
    .react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]):hover,
    .react-datepicker__month-text--in-range:not([aria-disabled=true]):hover,
    .react-datepicker__quarter-text--selected:not([aria-disabled=true]):hover,
    .react-datepicker__quarter-text--in-selecting-range:not([aria-disabled=true]):hover,
    .react-datepicker__quarter-text--in-range:not([aria-disabled=true]):hover,
    .react-datepicker__year-text--selected:not([aria-disabled=true]):hover,
    .react-datepicker__year-text--in-selecting-range:not([aria-disabled=true]):hover,
    .react-datepicker__year-text--in-range:not([aria-disabled=true]):hover {
        background-color: $primary;
    }
}
