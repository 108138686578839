.btn:disabled,
.btn.disabled {
    @extend .border-transparent;
}

.btn.disabled {
    pointer-events: all;
}

.btn.disabled:active,
.btn.disabled:focus {
    pointer-events: none;
}

.btn {
    border-radius: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
}

.btn-sm {
    //@extend .px-3;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
}

.btn-xs {
    @extend .btn;
    @extend .btn-sm;
    @extend .text-height-1;
    @extend .px-2;
    @extend .shadow-none;
    font-size: 12px;
    border-radius: 3px;
}

.btn-xxs {
    @extend .btn-xs;
    @extend .pt-0;
    @extend .pb-0;
    @extend .ps-0;
    @extend .pe-0;
}

.btn-rounded {
    @extend .rounded-5;
    @extend .border;
    @extend .px-3;
}

.btn:active {
    @extend .border-transparent;
}

.btn-ai {
    background: fade-out($secondary, .9);

    &:hover {
        background-color: fade-out($secondary, .8);
    }
}

.btn-group-bg {
    background-color: $gray-2;
}

.btn-primary:hover {
    color: rgba(255, 255, 255, .75);
}

.btn-outline-success:hover,
.btn-outline-info:hover,
.btn-outline-danger:hover {
    @extend .text-white;
}

.btn-plain {
    @extend .p-0;
    @extend .rounded-0;
    @extend .shadow-none;
    color: inherit;
    outline: none;
}

.btn-unstyled {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-plain;
    @extend .font-inherit;
    @extend .bg-transparent;
    @extend .border-0;
    @extend .shadow-none;
    text-align: inherit;
    color: inherit;
    font-size: inherit;
}

.btn-huge {
    @extend .btn;
    @extend .btn-sm;
    @extend .shadow-none;
    @extend .text-center;
    @extend .rounded-3;
    @extend .py-2;
}

.premium-label {
    @extend .bg-secondary;
    @extend .bg-opacity-10;
    @extend .p-1;
    @extend .border;
    @extend .border-secondary;
    @extend .rounded-1;
    @extend .small;
    @extend .text-secondary;
}

.btn-group {
    .btn:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
