.x-axis-label {
    font-size: 6px;
}

@include media-breakpoint-up(sm) {
    .x-axis-label {
        font-size: 10px;
    }
}

@include media-breakpoint-up(md) {
    .x-axis-label {
        font-size: 12px;
    }
}
