.height-90 {
    height: 90% !important;
}

.dvh-100 {
    height: 100dvh;
}

.dvh-90 {
    height: 90dvh;
}

.max-w-100 {
    max-width: 100%;
}

.min-w-33 {
    min-width: 33%;
}

.max-w-33 {
    max-width: 33%;
}

.max-w-50 {
    max-width: 50%;
}

.max-w-66 {
    max-width: 66%;
}

.max-w-75 {
    max-width: 75%;
}

.max-w-90 {
    max-width: 90%;
}

.max-h-100 {
    max-height: 100%;
}

.max-h-50 {
    max-height: 50%;
}

.max-h-66 {
    max-height: 66%;
}

.max-h-75 {
    max-height: 75%;
}

.w-inherit {
    width: inherit;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-33 {
    width: 33%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-75 {
    width: 75%;
}

.w-80 {
    width: 80%;
}

@include media-breakpoint-up(sm) {
    .w-sm-25 {
        width: 25%;
    }
    .w-sm-75 {
        width: 75%;
    }
}

@include media-breakpoint-up(md) {
    .w-md-33 {
        width: 33%;
    }
    .w-md-50 {
        width: 50%;
    }
    .max-w-md-50 {
        max-width: 50%;
    }
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.list-container {
    @extend .max-w-100;
    @extend .overflow-x-auto;
}

.gradient-text {
    @extend .position-relative;
    z-index: 0;

    &:after {
        content: '';
        @extend .position-absolute;
        @extend .d-block;
        height: 50%;
        z-index: 1;
        @extend .start-0;
        @extend .end-0;
        @extend .bottom-0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    &.expanded:after {
        height: 0;
    }
}

.my-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.mt-6 {
    margin-top: 4rem !important;
}

.mb-6 {
    margin-bottom: 4rem !important;
}

.my-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.mt-7 {
    margin-top: 5rem !important;
}

.mb-7 {
    margin-bottom: 5rem !important;
}

.m--1 {
    margin: -0.25rem;
}

.mx--1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.my--1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
}

.ms--1 {
    margin-left: -0.25rem;
}

.me--1 {
    margin-right: -0.25rem;
}

.mt--1 {
    margin-top: -0.25rem;
}

.mb--1 {
    margin-bottom: -0.25rem;
}

.m--2 {
    margin: -0.5rem;
}

.mx--2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.my--2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.ms--2 {
    margin-left: -0.5rem;
}

.me--2 {
    margin-right: -0.5rem;
}

.mt--2 {
    margin-top: -0.5rem;
}

.mb--2 {
    margin-bottom: -0.5rem;
}

.mx--3 {
    margin-left: -1rem;
    margin-right: -1rem;
}

.mx--4 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.mt--4 {
    margin-top: -1.5rem;
}

.my--3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.ms--3 {
    margin-left: -1rem;
}

.me--3 {
    margin-right: -1rem;
}

.mt--3 {
    margin-top: -1rem;
}

.mb--3 {
    margin-bottom: -1rem;
}

@each $bp, $num in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
        .mx-#{$bp}--1 {
            margin-left: -0.25rem;
            margin-right: -0.25rem;
        }
        .my-#{$bp}--1 {
            margin-top: -0.25rem;
            margin-bottom: -0.25rem;
        }
        .ms-#{$bp}--1 {
            margin-left: -0.25rem;
        }
        .me-#{$bp}--1 {
            margin-right: -0.25rem;
        }
        .mt-#{$bp}--1 {
            margin-top: -0.25rem;
        }
        .mb-#{$bp}--1 {
            margin-bottom: -0.25rem;
        }
        .mx-#{$bp}--2 {
            margin-left: -0.5rem;
            margin-right: -0.5rem;
        }
        .my-#{$bp}--2 {
            margin-top: -0.5rem;
            margin-bottom: -0.5rem;
        }
        .ms-#{$bp}--2 {
            margin-left: -0.5rem;
        }
        .me-#{$bp}--2 {
            margin-right: -0.5rem;
        }
        .mt-#{$bp}--2 {
            margin-top: -0.5rem;
        }
        .mb-#{$bp}--2 {
            margin-bottom: -0.5rem;
        }
        .mx-#{$bp}--3 {
            margin-left: -1rem;
            margin-right: -1rem;
        }
        .my-#{$bp}--3 {
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
        .ms-#{$bp}--3 {
            margin-left: -1rem;
        }
        .me-#{$bp}--3 {
            margin-right: -1rem;
        }
        .mt-#{$bp}--3 {
            margin-top: -1rem;
        }
        .mb-#{$bp}--3 {
            margin-bottom: -1rem;
        }
    }
}
