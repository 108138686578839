// Variable corporate identity: https://www.figma.com/file/V1XBy7bM1W1KwljfBn6Xy8/MVP-Prototype-3?node-id=3627%3A3915

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Styles are taken from Figma [Variable Colors](https://www.figma.com/file/X3CB3DfolatlUj16z2O60L/Variable-Colors?node-id=0%3A1)
@import "theme";

@import "font";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark.scss";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// Other, non-bootstrap css
@import "~react-popper-tooltip/dist/styles.css";

@import "react-datepicker/dist/react-datepicker.css";
@import "./datepicker.scss";

@import "reactflow/dist/style.css";

// 6. Add additional custom code here
html,
body {
    font-family: $font-family-base;
    overscroll-behavior-y: none;
}

#app-container {
    overscroll-behavior-y: auto;
    position: fixed;
    width: 100%;
}

#variable-content {
    width: 100%;
}

@include media-breakpoint-up(lg) {
    #app-container {
        position: relative;
    }

    #variable-nav {
        width: 200px;
    }

    #variable-content {
        width: calc(100% - 200px);
        padding-bottom: 0;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.text-alt-font {
    font-family: $font-family-flex;
}

.text-base-font {
    font-family: $font-family-base;
}

.text-code-font {
    font-family: $font-family-code;
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

.letter-spacing-2 {
    letter-spacing: 2px;
}

.focus-flex-grow-1:focus,
.focus-flex-grow-1:active {
    @extend .flex-grow-1;
}

.rotate {
    animation: spin 2s infinite cubic-bezier(.21, .64, .75, .37);
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

.fs-base {
    font-size: var(--bs-body-font-size);
}

.fs-inherit {
    font-size: inherit;
}

.font-inherit {
    font-family: inherit;
}

.text-inherit {
    color: inherit !important;
}

.outline-0 {
    outline: none !important;
}

.outline-top {
    position: relative;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: $secondary;
    }
}

.outline-bottom {
    position: relative;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: $secondary;
    }
}

.outline-1 {
    outline-style: solid;
    outline-width: 1px;
}

.outline-2 {
    outline-style: solid;
    outline-width: 2px;
}

.outline-primary {
    outline-color: $primary;
}

.outline-secondary {
    outline-color: $secondary;
}

.outline-light {
    outline-color: $light;
}

.outline-on-focus {
    outline-width: 0;

    &:focus {
        outline-style: solid;
        outline-width: 1px !important;
        outline-color: #0000002d;
    }
}

.outline-on-focus-2 {
    @extend .outline-on-focus;

    &:focus {
        outline-width: 2px !important;
    }
}

.square-1 {
    width: 1rem;
    height: 1rem;
}

.square-12 {
    width: 1.2rem;
    height: 1.2rem;
}

.square-15 {
    width: 1.5rem;
    height: 1.5rem;
}

.rotate-45 {
    transform: rotate(45deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate--90 {
    transform: rotate(-90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate--180 {
    transform: rotate(-180deg);
}

.rotate-180x {
    transform: rotateX(180deg);
}

.rotate--180x {
    transform: rotateX(-180deg);
}

.transition-all-1s {
    transition: all 1s;
}

.transition-all-100ms {
    transition: all .1s;
}

.transition-all-300ms {
    transition: all .3s;
}

.transition-bottom-300ms {
    transition: bottom .3s;
}

.transition-opacity-100ms {
    transition: opacity .1s;
}

.transition-opacity-300ms {
    transition: opacity .3s;
}

.transition-bg-1s {
    transition: background-color 1s;
}

.transition-bg-2s {
    transition: background-color 2s;
}

.transition-fs-200ms {
    transition: font-size .2s;
}

a {
    text-decoration: none;
}

p:last-child {
    @extend .mb-0;
}

.z-index-below {
    z-index: -100;
}

.z-index-dropdown {
    z-index: $zindex-dropdown;
}

.z-index-sticky {
    z-index: $zindex-sticky;
}

.z-index-fixed {
    z-index: $zindex-fixed;
}

.z-index-modal {
    z-index: $zindex-modal;
}

.z-index-off-canvas {
    z-index: $zindex-offcanvas;
}

.z-index-popover {
    z-index: $zindex-popover;
}

.z-index-tooltip {
    z-index: $zindex-tooltip;
}

.z-index-toast {
    z-index: $zindex-toast;
}

.text-white-25 {
    color: fade-out($white, .25)
}

.text-slightly-muted {
    color: $gray-700;
}

.text-very-muted {
    color: $gray-500;
}

.p-05 {
    padding: 0.15rem
}

.pill {
    @extend .py-1;
    padding-left: 12px;
    padding-right: 12px;
    @extend .rounded-5;
}

.pill-sm {
    @extend .py-0;
    padding-left: 10px;
    padding-right: 10px;
    @extend .small;
}

.pill-primary {
    @extend .pill;
    @extend .bg-primary;
    @extend .text-light;
}

.pill-light {
    @extend .pill;
    @extend .bg-body;
    @extend .text-muted;
}

.element-badge {
    @extend .d-inline-block;
    @extend .small;
    @extend .fw-bold;
    @extend .text-muted;
    @extend .rounded-1;
    @extend .bg-primary;
    @extend .bg-opacity-10;
    @extend .border;
    @extend .text-height-1;
    @extend .square-12;
    padding-top: 3px;
}

.fill-parent {
    @extend .position-absolute;
    @extend .top-0;
    @extend .bottom-0;
    @extend .start-0;
    @extend .end-0;
}

.top-1 {
    top: 1px;
}

.top--1 {
    top: -1px;
}

.top-2 {
    top: 2px;
}

.top--2 {
    top: -2px;
}

.top-3 {
    top: 3px;
}

.top--3 {
    top: -3px;
}

.top-4 {
    top: 4px;
}

.top--4 {
    top: -4px;
}

.top-5 {
    top: 5px;
}

.top--5 {
    top: -5px;
}

.swatch {
    @extend .d-inline-block;
    @extend .btn;
    @extend .btn-sm;
    @extend .p-0;
    width: 1rem;
    height: 1rem;
}

.text-strikethrough {
    text-decoration: line-through;
}

.text-height-inherit {
    line-height: inherit;
}

.text-height-1 {
    line-height: 1;
}

.text-height-1_2 {
    line-height: 1.2;
}

.text-height-1_4 {
    line-height: 1.4;
}

.text-height-1_5 {
    line-height: 1.5;
}

.text-height-2 {
    line-height: 2;
}

.help-window {
    @extend .alert;
    @extend .fw-normal;
    @extend .bg-info;
    @extend .bg-opacity-10;
}

.opacity-10 {
    opacity: .1 !important;
}

.opacity-hover-25:hover {
    opacity: .25 !important;
}

.opacity-hover-50:hover {
    opacity: .5 !important;
}

.opacity-hover-75:hover {
    opacity: .75 !important;
}

.opacity-hover-100:hover {
    opacity: 1 !important;
}

.opacity-90 {
    opacity: .9 !important;
}

.offcanvas.offcanvas-end:not(.show) {
    right: $spacer * -1;
}

.variable-table {
    th,
    td {
        padding: $table-cell-padding-x-sm;
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //overflow-x: auto;
        //overflow-y: hidden;
    }

    tr.slim {
        td {
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
        }
    }

    .input-item {
        &:not(:first-child) {
            > td {
                border-top-width: 1px !important;
            }
        }

        > td {
            border-bottom-width: 0 !important;
        }
    }

    .input-type {
        > td {
            border-bottom-width: 0 !important;
        }
    }
}

tr.highlight-input {
    @extend .outline-1;

    > td {
        @extend .bg-info;
        @extend .bg-opacity-10;
    }
}

.table-p-1 {
    th:first-child, td:first-child {
        @extend .ps-0;
    }

    th, td {
        @extend .p-0;
        @extend .pb-1;
        @extend .pe-1;
    }

    tr:last-child td {
        @extend .pb-0;
    }
}

.scope-summary .summary-cell {
    @extend .p-2;
}

abbr[title] {
    text-decoration: none;
    cursor: default;
}

.form-check-input {
    border-color: #BFBBAE;
}

.form-control.disabled,
.form-select.disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
}

.form-select {
    width: auto;
}

.form-hover-select {
    @extend .position-relative;
    @extend .p-1;
    @extend .rounded-1;

    &:hover, &.selected {
        //@extend .bg-light;
        @extend .bg-primary-hover;

        &:after {
            content: '▾';
            @extend .position-absolute;
            //@extend .bg-inherit;
            @extend .end-0;
            @extend .px-1;
        }
    }
}

.menu-item-selected {
    @extend .position-relative;

    .menu-item-name {
        @extend .fw-bold;
    }

    &:before {
        content: '';
        @extend .position-absolute;
        @extend .top-0;
        @extend .bottom-0;
        @extend .start-0;
        @extend .border-start;
        @extend .border-3;
        @extend .border-primary;
    }
}

.bg-light-hover:hover {
    @extend .bg-light;
}

.bg-body-hover:hover {
    @extend .bg-body;
}

.bg-dark-hover:hover {
    @extend .bg-dark;
}

.bg-none-hover:hover {
    @extend .bg-transparent;
}

.bg-details-hover:hover {
    background-color: fade-out($gray-2, .20) !important;
}

.bg-primary-hover:hover {
    background-color: fade-out($primary, .90) !important;
}

.bg-secondary-hover:hover {
    background-color: $secondary !important;
    @extend .bg-opacity-25;
}

.bg-danger-hover:hover {
    background-color: $danger !important;
    @extend .bg-opacity-25;

    svg {
        fill: $white;
    }
}

.bg-hover-none:hover {
    background-color: transparent !important;
}

.bg-transparent {
    background: transparent;
}

.bg-ghg-upstream {
    background-color: $upstream;
}

.bg-ghg-scope2 {
    background-color: $scope2;
}

.bg-ghg-scope1 {
    background-color: $scope1;
}

.bg-ghg-direct {
    background-color: $direct;
}

.bg-ghg-downstream {
    background-color: $downstream;
}

$lcs-a1-color: #6675FF;
$lcs-a2-color: #6675FF;
$lcs-a3-color: #9CDD91;
$lcs-a4-color: #9CDD91;
$lcs-b-color: #FF9966;
$lcs-c-color: #FF9966;
$lcs-d-color: #FFD47F;
$lcs-u-color: $gray-200;

.border-a1,
.border-a2 {
    border-color: $lcs-a1-color !important;
}

.border-a3,
.border-a4 {
    border-color: $lcs-a3-color !important;
}

.border-b,
.border-c,
.border-d {
    border-color: $lcs-b-color !important;
}

.bg-lcs-a1,
.bg-lcs-a2 {
    background-color: $lcs-a1-color;
}

.bg-lcs-a3,
.bg-lcs-a4 {
    background-color: $lcs-a3-color;
}

.bg-lcs-b,
.bg-lcs-c {
    background-color: $lcs-b-color;
}

.bg-lcs-d {
    background-color: $lcs-d-color;
}

.bg-lcs-u {
    background-color: $lcs-u-color;
}

$striped-line-width: 3px;
.bg-ghg-scope2-striped,
.bg-secondary-striped {
    background: repeating-linear-gradient(145deg, $secondary, $secondary $striped-line-width, fade-out($secondary, .50) $striped-line-width, fade-out($secondary, .50) $striped-line-width * 2);
}

.bg-ghg-upstream-striped,
.bg-primary-striped {
    background: repeating-linear-gradient(145deg, $primary, $primary $striped-line-width, fade-out($primary, .50) $striped-line-width, fade-out($primary, .50) $striped-line-width * 2);
}

.bg-ghg-downstream-striped,
.bg-info-striped {
    background: repeating-linear-gradient(145deg, $info, $info $striped-line-width, fade-out($info, .50) $striped-line-width, fade-out($info, .50) $striped-line-width * 2);
}

.bg-ghg-scope1-striped,
.bg-danger-striped {
    background: repeating-linear-gradient(145deg, $danger, $danger $striped-line-width, fade-out($danger, .50) $striped-line-width, fade-out($danger, .50) $striped-line-width * 2);
}

.bg-warning-striped {
    background: repeating-linear-gradient(145deg, $warning, $warning $striped-line-width, fade-out($warning, .50) $striped-line-width, fade-out($warning, .50) $striped-line-width * 2);
}

.bg-dark-striped {
    background: repeating-linear-gradient(145deg, $dark, $dark $striped-line-width, fade-out($dark, .50) $striped-line-width, fade-out($dark, .50) $striped-line-width * 2);
}

.bg-details {
    background-color: $gray-2;
}

.bg-dividers {
    background-color: $dividers;
}

.bg-body {
    background-color: $gray-0 !important;
}

.rounded-primary-hover:hover {
    border-color: fade-out($primary, .90);
}

.rounded-start-1 {
    border-bottom-left-radius: var(--bs-border-radius-sm) !important;
    border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-1 {
    border-bottom-right-radius: var(--bs-border-radius-sm) !important;
    border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.required {
    @extend .outline-1;
    outline-color: darken($danger, 10%) !important;
}

.variable-form-select {
    @extend .form-select;
    @extend .form-select-sm;
    @extend .shadow-none;
    @extend .text-overflow-ellipsis;
    color: $primary !important;
    border: 0;
    padding-right: 1.5rem;
    width: auto;
    background-image: none;

    &:hover:not(:disabled) {
        background-color: fade-out($primary, .90);
    }

    &.empty,
    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &.active {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        //background-position: right 0.25rem center;
        padding-right: 2rem;
        background-size: 16px 12px;
    }

    &.empty,
    &:focus:not(:disabled),
    &.active {
        @extend .bg-light;
    }

    &:disabled {
        @extend .bg-transparent;
        color: $hint;
    }

    &.native-invalid {
        border-color: darken($danger, 10%);
        color: darken($danger, 10%);
    }
}

.input-element {
    @extend .btn;
    @extend .btn-sm;
    @extend .shadow-none;
    @extend .rounded-1;
    @extend .px-2;
    @extend .py-1;
    @extend .rounded-1;
    @extend .text-primary;
    @extend .max-w-100;

    &.active {
        @extend .bg-primary;
        @extend .bg-opacity-10;
    }

    &:not(:disabled) {
        @extend .bg-primary-hover;
        @extend .rounded-primary-hover;
    }

    &:disabled, &.disabled {
        @extend .bg-hover-none;
        @extend .opacity-100;
        @extend .text-primary;
    }
}

.variable-input-group {
    @extend .input-group;
    @extend .input-group-sm;
    width: auto;

    .variable-form-control,
    .variable-form-control:focus,
    .variable-form-control:hover,
    .variable-form-control.active {
        @extend .border-0;
        border-radius: 0;
        background-color: inherit;
    }

    > * .variable-form-control,
    > * .variable-form-control:focus,
    > * .variable-form-control:hover,
    > * .variable-form-control.active {
        border-radius: inherit;
    }

    > *:first-child {
        border-top-left-radius: inherit !important;
        border-bottom-left-radius: inherit !important;
    }


    > *:last-child {
        border-top-right-radius: inherit !important;
        border-bottom-right-radius: inherit !important;
    }
}

.variable-input-group-text {
    @extend .input-group-text;
    @extend .m-0;
    border-width: 0 0 0 1px;
    border-color: inherit;
    border-style: inherit;
    background-color: inherit;
    color: inherit;
}

.variable-form-control {
    @extend .form-control;
    @extend .form-control-sm;
    @extend .shadow-none;
    @extend .border-transparent;
    color: $primary !important;
    //@extend .bg-light;
    width: auto;

    &.border {
        @extend .border;
    }

    &.border-0 {
        @extend .border-0;
    }

    &.bg-white {
        @extend .bg-white;
    }

    &:focus, &.active,
    &:hover:not(:focus):not(:disabled) {
        background-color: fade-out($primary, .95) !important;

        &.bg-hover-none {
            background-color: transparent !important;
        }
    }

    &:focus, &.active {
        @extend .border;
    }

    &:disabled, &.disabled {
        background-color: transparent;
        color: $hint !important;
    }

    &.required,
    &.native-invalid,
    &.is-invalid,
    &.is-invalid:focus,
    &.is-invalid.active {
        @extend .required;
    }
}

input.variable-form-control {
    @extend .text-overflow-ellipsis;
}

.variable-form-control-minimal {
    @extend .d-block;
    @extend .w-100;
    @extend .text-primary;
    @extend .text-height-inherit;
    @extend .p-0;
    @extend .bg-transparent;
    @extend .bg-hover-none;
    @extend .border-0;
    @extend .outline-0;
}

.variable-selector {
    @extend .position-relative;
    @extend .d-block;
    @extend .py-1;
    @extend .pe-4;
    @extend .ps-2;
    @extend .rounded-1;
    color: $primary !important;

    &:hover,
    &:focus {
        @extend .bg-light;
    }

    &:before {
        content: '▾';
        visibility: hidden;
        @extend .position-absolute;
        @extend .end-0;
        @extend .me-2;
    }

    &:hover:before {
        visibility: visible;
    }

    &.required {
        @extend .required;
    }
}

$arrow-white: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e";

.variable-select-arrow {
    @extend .form-select;
    @extend .form-select-sm;
    @extend .shadow-none;
    padding-right: 2rem !important;
    color: $primary !important;

    &:focus,
    &.open {
        @extend .border;
    }

    &:focus {
        @extend .outline-1;
    }

    &.required {
        @extend .required;
    }

    &.bg-primary:not(.bg-opacity-10) {
        background-image: url($arrow-white);
    }
}

.variable-select-arrow-sm {
    @extend .form-select;
    @extend .form-select-sm;
    @extend .shadow-none;
    @extend .pe-4;
    color: $primary;
    border: transparent;
    background-position: right 0.3rem center;

    &:focus {
        @extend .outline-1;
    }

    &.required {
        @extend .required;
    }

    &.bg-primary:not(.bg-opacity-10) {
        background-image: url($arrow-white);
    }
}

.dropdown-item {
    &.active {
        @extend .bg-primary;
        @extend .bg-opacity-10;
        @extend .text-primary;
    }

    &.selected {
        @extend .border-2;
        @extend .border-start;
        @extend .border-primary;
        @extend .bg-primary;
        @extend .bg-opacity-5;
    }

    &:disabled,
    &.disabled {
        @extend .text-muted;
    }
}


.variable-radio-control {
    @extend .d-inline-block;
    @extend .px-3;
    @extend .py-2;
    @extend .border;

    &:not(.label-checked) {
        @extend .bg-light;
        @extend .border-light;
    }

    &.label-checked {
        @extend .bg-primary;
        @extend .bg-opacity-10;
        @extend .border-primary-light;
    }

    &.required {
        @extend .required;
    }
}

.tooltip-container .dropdown-item {
    @extend .bg-light-hover;
    @extend .py-1;
    @extend .px-2;
}

.chart-tooltip {
    @extend .position-absolute;
    @extend .z-index-popover;
    @extend .bg-white;
    @extend .small;
    @extend .border;
    @extend .rounded-1;
    @extend .shadow-sm;
    @extend .p-2;
    @extend .not-clickable;
    @extend .text-nowrap;
    @extend .text-center;
    @extend .overflow-hidden;
    @extend .opacity-0;
    @extend .visibility-hidden;
    transition: opacity linear 100ms;

    &.chart-tooltip-active {
        @extend .visible;
        @extend .opacity-100;
    }
}

.dropdown-hover:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

.dropdown-click.focused .dropdown-menu,
.dropdown-click:focus .dropdown-menu,
.dropdown-click:active .dropdown-menu,
.dropdown-click > *:active,
.dropdown-click > *:focus,
.dropdown-menu:hover {
    display: block;
}

.dropdown-click:focus-within .dropdown-menu,
.dropdown-click:focus-visible .dropdown-menu,
.dropdown-click > *:focus-within,
.dropdown-click > *:focus-visible {
    display: block;
}

.dropdown-item-average {
    background-color: fade-out($warning, .9);
}

.dropdown-item-primary {
    background-color: fade-out($success, .9);
}

.show-on-focus,
.show-on-click {
    display: none;
}

.focused .show-on-focus,
.focused .show-on-click {
    display: block;
}

.show-on-hover {
    visibility: collapse;
}

.hover-parent:hover .show-on-hover {
    visibility: visible;
}

.hover-parent-direct:hover > .show-on-hover {
    visibility: visible;
}

.hide-on-hover {
    visibility: visible;
}

.hover-parent:hover .hide-on-hover {
    visibility: collapse;
}

.hover-parent-direct:hover > .hide-on-hover {
    visibility: collapse;
}

.visibility-hidden {
    visibility: hidden;
}

.display-on-hover {
    display: none;
}

.display-on-hover-inline {
    display: none;
}

.hover-parent:hover .display-on-hover {
    display: block;
}

.hover-parent:hover .d-none-on-hover {
    display: none;
}

.hover-parent:hover .display-on-hover-inline {
    display: inline-block;
}

.hover-parent-direct:hover > .display-on-hover {
    display: block;
}

.hover-parent-direct:hover > .d-none-on-hover {
    display: none;
}

.hover-parent-direct:hover > .display-on-hover-inline {
    display: inline-block;
}

.d-table .d-table-cell {
    padding: 0.5rem;
}

.d-table-sm .d-table-cell {
    @extend .p-1;
}

.table-layout-fixed {
    table-layout: fixed;
}

.table-header .d-table-cell,
.d-table-header-group .d-table-cell {
    &:not(.fw-normal) {
        @extend .fw-bold;
    }

    color: inherit !important;

    border-bottom: 2px solid fade-out($dark, .8) !important;
}

.table > :not(caption) > * > * {
    color: inherit !important;
}

.table-body .d-table-cell,
.d-table-row-group:not(.border-0) .d-table-cell {
    @extend .border-bottom;
}

.table-footer > div {
    @extend .fw-bold;
    border-top: 2px solid fade-out($dark, .8) !important;
}

.table > tbody,
.table > .table-body {
    border-top: 2px solid fade-out($dark, .8) !important;
}

.d-table-caption {
    display: table-caption;
}

.d-table-header-group {
    display: table-header-group;
}

.d-table-footer-group {
    display: table-footer-group;
}

.d-table-row-group {
    display: table-row-group;
}

.d-table-col-group {
    display: table-column-group;
}

.d-table-col {
    display: table-column;
}

.table td {
    @extend .bg-transparent;
}

// thanks: https://codingrabbithole.com/react-aligned-scrollable-table-body-with-fixed-header/
.table-scrollable {
    .d-table-row,
    .d-table-header-group,
    .d-table-row-group,
    .table-header,
    .table-body,
    thead, tbody, tr {
        display: table !important;
        width: 100% !important;
        table-layout: fixed !important;
    }

    .table-body,
    .d-table-row-group,
    tbody {
        display: block !important;
        overflow: auto !important;
        table-layout: fixed !important;
    }
}

.variable-nav-item-cx {
    &.active {
        background-color: #004A57;
    }
}

.variable-nav-item {
    @extend .d-flex;
    @extend .align-items-baseline;
    @extend .text-height-1;
    @extend .py-2;
    @extend .px-3;

    &:hover {
        @extend .text-white;
    }

    &.active {
        @extend .text-white;
        background-color: #006471;
    }

    &:not(.active) {
        @extend .text-white-25;
    }

    .variable-nav-item-text {
        @extend .position-relative;
        top: 2px;
    }
}

.variable-nav-header {
    @extend .bg-primary;
    @extend .mt-2;
    @extend .px-3;
    @extend .py-1;
    @extend .small;
    @extend .text-body;
    @extend .not-selectable;
    color: $gray-600 !important;
}

.tag {
    position: relative;
    display: inline-block;
    background-color: rgba(197, 221, 232, 0.48);
    padding: .2rem .6rem;
    margin: 0 .2rem .2rem 0;
    border-radius: 3px;
    white-space: nowrap;
    cursor: default;
}

.tag-sm {
    padding: .1rem .3rem;
    margin: 0 .2rem .2rem 0;
    border-radius: 1px;
    font-size: $small-font-size;
}

.tag-remove, .tag-remove:hover {
    position: absolute;
    top: .4rem;
    right: .2rem;
    bottom: .4rem;
    display: none;
    padding: 0 .2rem;
    border-right: 4px;
    background-color: #6e7679;
    color: $white;
    line-height: 1;
    margin-left: .2rem;
}

.tag:hover {
    background-color: rgba(197, 221, 232, 0.78);
}

.tag:hover .tag-remove {
    display: inline-block;
}

.taxonomy {
    @extend .text-lowlight;
    margin: 0 .2rem .2rem 0;
    @extend .px-1;
}

.taxonomy:hover {
    background-color: $gray-400;
}

.taxonomy-sm {
    @extend .small;
}

.card-alert {
    @extend .py-2;
    margin: add(-1rem, -1px);
    border-radius: 0;
}

.card-alert-bottom {
    @extend .mt-4;
    //border-radius: 0 0 $border-radius $border-radius;
}

.bg-inherit {
    background-color: inherit;
}

.form-control.border-info:focus {
    box-shadow: 0 0 0 0.25rem transparentize($info, .7);
}

.form-control.border-warning:focus {
    box-shadow: 0 0 0 0.25rem transparentize($warning, .7);
}

.form-control.border-success:focus {
    box-shadow: 0 0 0 0.25rem transparentize($success, .7);
}

$shadows: (
    'noon': 0 0 0.5rem 0 rgba(0, 0, 0, 0.1),
    'noon-sm': 0 0 0.125rem 0 rgba(0, 0, 0, 0.1),
    'noon-lg': 0 0 1rem 0 rgba(0, 0, 0, 0.1),
    'inset': inset 0 0 10px 0 rgba(0, 0, 0, 0.1),
    'inset-sm': inset 0 0 5px 0 rgba(0, 0, 0, 0.1),
);

.border-width-0 {
    border-width: 0 !important;
}

@each $breakpoint, $_num in $grid-breakpoints {
    @each $border, $width in $border-widths {
        @if $breakpoint == 'xs' {
            .border-width-#{$border} {
                border-width: $width !important;
            }
        } @else {
            @include media-breakpoint-up($breakpoint) {
                .border-width-#{$breakpoint}-#{$border} {
                    border-width: $width !important;
                }
            }
        }
    }
    @each $name, $shadow in $shadows {
        .shadow-#{$name} {
            box-shadow: $shadow;
        }
        @if $breakpoint != 'xs' {
            @include media-breakpoint-up($breakpoint) {
                .shadow-#{$breakpoint}-#{$name} {
                    box-shadow: $shadow;
                }
            }
        }
    }
}

.text-direction-ltr {
    direction: ltr;
}

.text-direction-rtl {
    direction: rtl;
}

.text-hover-white:hover {
    @extend .text-white;
}

.text-highlight {
    background-color: $highlight-color;
    white-space: nowrap;
}

.text-lowlight {
    background-color: $gray-200;
    white-space: nowrap;
}

.text-wrap-hyphens {
    hyphens: auto;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-word-break {
    white-space: break-spaces;
}

.text-word-break-all {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.variable-striped {
    > *:nth-child(even) {
        @extend .bg-light;
    }
}

.indent-quote {
    @extend .ps-2;
    @extend .border;
    @extend .border-0;
    @extend .border-start;
    @extend .border-2;
}

.hover-parent:hover .underline-on-hover-parent,
.underline-on-hover:hover {
    text-decoration: underline;
}

.typeahead .dropdown-menu {
    width: auto !important;
    font-size: $font-size-sm;
    @extend .shadow-lg;
}

.dropdown-fluid .dropdown-menu {
    min-width: 0;
}

.pulsating-circle-pseudo:before {
    content: ' ';
    @extend .d-inline-block;
    @extend .rounded-circle;
    @extend .me-1;
    line-height: 1;
    color: $info;
    border: 5px solid $info;
    transition: all;
    outline: 5px solid fade-out($info, .50);
    animation: pulse-dot-live 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-dot-live {
    0% {
        outline-width: 0;
        outline-color: fade-out($info, .5);
    }
    80%, 100% {
        outline-width: 8px;
        outline-color: transparent;
    }
}

.label-live {
    @extend .d-inline-block;
    @extend .rounded-5;
    @extend .small;
    color: $white;
    padding: 4px 8px 2px;
    line-height: 1;
    background-color: $info;
    outline: 5px solid fade-out($info, .50);
    transition: outline-color;
    animation: pulse-label-live 5s linear infinite;
}

@keyframes pulse-label-live {
    0%, 100% {
        outline-color: fade-out($info, .50);
    }
    50% {
        outline-color: fade-out($info, .80);
    }
}

.dot-notification,
.dot-connected,
.dot-pending,
.dot-not-connected {
    @extend .d-inline-block;
    @extend .rounded-circle;
    width: 0.75rem;
    height: 0.75rem;
    align-self: center;
}

.dot-notification {
    @extend .bg-success;
    @extend .bg-opacity-90;
}

.dot-not-connected {
    @extend .bg-dark;
    @extend .bg-opacity-25;
}

.dot-connected {
    @extend .bg-info;
    @extend .bg-opacity-75;
}

.dot-pending {
    @extend .bg-secondary;
    @extend .bg-opacity-75;
}

.dot-sm {
    width: .5rem;
    height: .5rem;
}

.label-solution {
    @extend .d-inline-block;
    @extend .rounded-5;
    @extend .small;
    color: $white;
    font-weight: 500;
    padding: 4px 8px 2px;
    line-height: 1;
    background-color: $secondary;
    outline: 5px solid fade-out($secondary, .50);
    transition: outline-color;
    animation: pulse-label-solution 5s linear infinite;
}

.label-epd {
    @extend .d-inline-block;
    @extend .rounded-1;
    @extend .small;
    color: $white;
    font-weight: 500;
    padding: 4px 7px 2px;
    line-height: 1;
    background-color: $hint;
}

@keyframes pulse-label-solution {
    0%, 100% {
        outline-color: fade-out($secondary, .50);
    }
    50% {
        outline-color: fade-out($secondary, .80);
    }
}

.label-model {
    @extend .d-inline-block;
    @extend .rounded-5;
    @extend .small;
    color: $white;
    font-weight: 500;
    padding: 4px 8px 2px;
    line-height: 1;
    background-color: $primary;
    //outline: 5px solid fade-out($primary, .50);
    //transition: outline-color;
    //animation: pulse-label-model 5s linear infinite;
}

@keyframes pulse-label-model {
    0%, 100% {
        outline-color: fade-out($primary, .50);
    }
    50% {
        outline-color: fade-out($primary, .80);
    }
}

.keyboard-shortcut {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .text-center;
    @extend .bg-dark;
    @extend .bg-opacity-10;
    @extend .p-1;
    @extend .small;
    @extend .text-height-1;
    //@extend .text-alt-font;
    @extend .rounded-1;
    @extend .text-nowrap;
    //@extend .square-15;
    min-width: 1.2rem;
    min-height: 1.2rem;
    font-family: $font-family-monospace;
    text-transform: uppercase;

    & + .keyboard-shortcut {
        margin-left: 2px;
    }
}

.item-list {
    @extend .position-relative;
    @extend .d-block;
    @extend .clickable;
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover::before {
        content: ' ';
        @extend .rounded-2;
        @extend .d-block;
        @extend .position-absolute;
        pointer-events: none;
        top: 0;
        right: -7px;
        left: -7px;
        bottom: 0;
        @extend .bg-light;
    }

    &::after {
        content: ' ';
        @extend .d-block;
        @extend .position-absolute;
        background: transparent;
        pointer-events: none;
        top: 0;
        right: 2px;
        left: 2px;
        bottom: 0;
        @extend .border-bottom;
        @extend .border-last-none;
    }

    &:first-child {
        margin-top: -12px;
    }

    &:last-child {
        margin-bottom: -12px;
    }

    > * {
        @extend .position-relative;
    }
}

.fill-primary {
    fill: $primary;
}

.fill-secondary {
    fill: $secondary;
}

.ms-auto {
    margin-left: auto;
}

.me-auto {
    margin-right: auto;
}

.my-last-none:last-child {
    margin-bottom: 0 !important;
}

.py-last-none:last-child {
    padding-bottom: 0 !important;
}

.border-white-faded {
    border-color: fade-out($white, .5) !important;
}

.border-medium-dark {
    border-color: lighten($dark, 50%) !important;
}

.border-primary-light {
    border-color: fade-out($primary, .8) !important;
}

.border-secondary-light {
    border-color: fade-out($secondary, .8) !important;
}

.border-scope1 {
    border-color: $scope1 !important;
}

.border-scope2 {
    border-color: $scope2 !important;
}

.border-upstream {
    border-color: $upstream !important;
}

.border-direct {
    border-color: $direct !important;
}

.border-downstream {
    border-color: $downstream !important;
}

.border-body {
    border-color: $body !important;
}

.border-divider {
    border-color: $dividers;
}

.border-last-none:last-child {
    border: none !important;
}

.border.border-dotted {
    border-style: dotted !important;
}

.border-start.border-dotted {
    border-left-style: dotted !important;
}

.border-end.border-dotted {
    border-right-style: dotted !important;
}

.border-top.border-dotted {
    border-top-style: dotted !important;
}

.border-bottom.border-dotted {
    border-bottom-style: dotted !important;
}

.border.border-dashed {
    border-style: dashed !important;
}

.border-start.border-dashed {
    border-left-style: dashed !important;
}

.border-end.border-dashed {
    border-right-style: dashed !important;
}

.border-top.border-dashed {
    border-top-style: dashed !important;
}

.border-bottom.border-dashed {
    border-bottom-style: dashed !important;
}

.border-primary-hover:hover {
    @extend .border-primary;
}

.border-hover:hover {
    @extend .border;
}

.border-transparent {
    border-color: transparent !important;
}

.border-opacity-50 {
    border-color: rgba(0, 0, 0, 0.10) !important;
}

.d-flex:not(.flex-column).flex-border-between > * + * {
    @extend .border-start;
    @extend .ps-3;
}

.flex-column.flex-border-between > * + * {
    @extend .border-top;
    @extend .pt-3;
}

.highlight-row {
    @extend .bg-info;
    @extend .bg-opacity-25;
}

.hovering-node {
    //> div {
    //    @extend .bg-primary;
    //    @extend .bg-opacity-10;
    //}

    &:after {
        content: '';
        @extend .position-absolute;
        @extend .d-block;
        @extend .top-0;
        @extend .bottom-0;
        @extend .border;
        @extend .border-primary;
    }

    &.reorder-start:after {
        left: -25px;
        right: auto;
    }

    &.reorder-end:after {
        left: auto;
        right: -25px;
    }
}

.flow-org-node-transition {
    transition: background 2000ms, border-color 2000ms;
}

.filter-label {
    @extend .px-1;
    @extend .rounded-1;
    background-color: #f4f4f4;

    &.active {
        background-color: #eaf4f4;
    }

    &:hover {
        background-color: #e6eaeb;
    }
}

.text-unknown {
    color: $gray-1;
}

.text-primary-hover:hover {
    @extend .text-primary;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, .3);
}

.blurred-co2e:before {
    content: 'D3.M0';
    filter: blur(5px);
    @extend .d-inline-block;
}

.lightly-blurred-text {
    filter: blur(3px);
}

.blurred-text {
    filter: blur(5px);
}

.bg-tag {
    background-color: rgba(197, 221, 232, 0.48);
}

.bg-opacity-0 {
    --bs-bg-opacity: 0;
}

.bg-opacity-5 {
    --bs-bg-opacity: 0.05;
}

.bg-opacity-90 {
    --bs-bg-opacity: 0.9;
}

.bg-opacity-85 {
    --bs-bg-opacity: 0.85;
}

.bg-opacity-10-hover:hover {
    --bs-bg-opacity: 0.10;
}

.bg-opacity-25-hover:hover {
    --bs-bg-opacity: 0.25;
}

.bg-opacity-50-hover:hover {
    --bs-bg-opacity: 0.50;
}

.bg-opacity-75-hover:hover {
    --bs-bg-opacity: 0.75;
}

// thanks: https://css-tricks.com/building-skeleton-screens-css-custom-properties/
@keyframes placeHolderShimmer {
    0% {
        background-position: 0 0
    }
    100% {
        background-position: 1000px 0
    }
}

.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, transparent 0, #efefef 500px, transparent 1000px);
}

.bg-loading {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .z-index-fixed;
    @extend .fill-parent;
    @extend .bg-white;
    @extend .bg-opacity-50;
}

$background-blur: 5px;
.blur-background {
    backdrop-filter: blur($background-blur);
    -webkit-backdrop-filter: blur($background-blur);
}

.alert-success.bg-opacity-10 {
    background-color: fade-out($success, .9);
}

.grayscale {
    filter: grayscale(100%) opacity(.9);
}

.cursor-default {
    cursor: default !important;
}

.cursor-text {
    cursor: text !important;
}

.grab {
    cursor: grab !important;
}

.grabbing {
    cursor: grabbing !important;
}

.cursor-reorder-y {
    cursor: ew-resize !important;
}

.cursor-reorder-x {
    cursor: ns-resize !important;
}

.clickable,
.hover-clickable:hover:not(:focus) {
    cursor: pointer;
}

.not-clickable {
    pointer-events: none;
}

.not-selectable {
    user-select: none;
}

.text-editable {
    cursor: text;
}

.smaller {
    font-size: 0.75rem;
}

.very-small {
    font-size: 0.675rem;
}

.product-link {
    padding: 0.25rem;
    margin: -0.25rem;
    border: 1px solid transparent;
    border-radius: 5px;

    &:hover {
        background-color: $gray-100;
        border: 1px solid $gray-200;
    }
}

.product-search .dropdown-menu {
    max-width: 400px;
}

#unit-search {
    left: 0;
    transform: translate3d(0, 31px, 0) !important;
    @extend .small;
}

.bg-gradient-tb-primary {
    background: linear-gradient(180deg, fade-out($primary, .7) 0%, fade-out($primary, 1) 100%);
}

.bg-gradient-tb-secondary {
    background: linear-gradient(180deg, fade-out($secondary, .8) 0%, fade-out($secondary, 1) 100%);
}

.bg-gradient-tb-light {
    background: linear-gradient(180deg, fade-out($light, 0) 0%, fade-out($light, 1) 100%);
}

.image-fit {
    width: 50px;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
}

.bg-image-100px {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100px auto;
    padding-left: 110px;
}

.bg-image-50px {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 50px auto;
    padding-left: 60px;
}

.bg-image-30px {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 30px auto;
    padding-left: 35px;
}

.bg-image-middle {
    background-position-y: center;
}

.product-image {
    width: auto;
    height: 100px;
}

@include media-breakpoint-up(sm) {
    .product-image {
        width: 160px;
        height: auto;
    }
}

.text-overflow-ellipsis {
    @extend .overflow-hidden;
    @extend .text-nowrap;
    text-overflow: ellipsis;
}

.flip-horizontally {
    transform: scaleY(-1);
}

.non-interactive {
    pointer-events: none;
}

.cannot-connect {
    @extend .d-inline-block;
    @extend .position-relative;
    @extend .rounded-circle;
    @extend .border;
    @extend .bg-light;
    top: 2px;
    width: 15px;
    height: 15px;
}

.not-connected {
    @extend .d-inline-block;
    @extend .position-relative;
    @extend .rounded-circle;
    @extend .bg-warning;
    @extend .bg-opacity-50;
    top: 2px;
    width: 15px;
    height: 15px;
}

.gm-style-iw {
    button {
        @extend .d-none;
    }
}
