$yellow: #FFAA00;
$yellow-hover: #FFCC66;
$highlight-color: #fff4a1;
$aqua: #009999;
$aqua-hover: #00676C;
$blue-medium: #003540; // text-placeholder in design system?
$blue-dark: #0A1D21;
$body: #526266; // Text 2
$hint: #7E8A8C; // Text 3
$dividers: #CCC9BC; // Gray 2
$gray-1: #E6E3DD;
$gray-2: #EBE9E2;
$gray-0: #F5F4F0;
$white: #fff;
$red: #FF5500;
$red-light: #FF9966;
$signal-green: #00990F;
$signal-red: #E50E00;
$data-light-blue: #9AC6C6;

// from Silja
$silja2: #F4F3F0;
$tag-low: #EBE8DD;
$silja3: #D9D5C7;

// this is testing for what the defaults are in pixels
//$spacer: 1rem;
//$spacer: 16px;
//$font-size-base: 16;

//$body-bg: $gray-0;
$border-radius: 8px;

$primary: $blue-medium;
$secondary: $yellow;
$info: $aqua;
$success: $signal-green;
$danger: $red;

$scope1: #EA5A1C;
$scope2: $yellow;
$upstream: $blue-medium;
$direct: $yellow;
$downstream: $aqua;

$headings-color: $blue-medium;
$headings-font-weight: 400;

$body-color: $blue-medium;
$body-secondary-color: $hint;

$btn-border-radius: 24px;
$input-bg: $white;
$input-disabled-bg: $gray-0;
$input-color: $blue-medium;
$input-placeholder-color: $hint;
$input-border-color: $dividers;
$input-focus-border-color: $blue-medium;

$arrow-white: "data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e";
