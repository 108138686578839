// big thanks: https://drublic.de/blog/printing-the-web

@page {
    margin: 1cm;
    padding: 0;
}

body {
    print-color-adjust: exact;
    padding: 0;
    font-size: small;
}

.card,
.card-body {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.p-2 {
    padding: .25rem !important;
}

.container,
.container-fluid {
    width: 100%;
    margin: 0;
    padding: 0;
}

.row,
.row > .col {
    display: block !important;
    width: 100% !important;
}

header, nav {
    display: none;
}

//a[href]:after {
//    content: " (" attr(href) ")";
//    word-wrap: break-word;
//}

a[href^="#"]:after,
a[href^="javascript"]:after {
    content: "";
    word-wrap: break-word;
}

//abbr[title]:after {
//    content: " (" attr(title) ")";
//    word-wrap: break-word;
//}
@page tables {
    size: landscape;
}

table {
    page: tables;
}

h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
}
