// thanks: https://css-tricks.com/snippets/sass/luminance-color-function/
@function luminance($color) {
    $colors: (
        'red': red($color),
        'green': green($color),
        'blue': blue($color)
    );

    @each $name, $value in $colors {
        $adjusted: 0;
        $value: calc($value / 255);

        @if $value < 0.03928 {
            $value: calc($value / 12.92);
        } @else {
            $value: calc(($value + .055) / 1.055);
            $value: pow($value, 2.4);
        }

        $colors: map-merge($colors, ($name: $value));
    }

    @return (map-get($colors, 'red') * .2126) + (map-get($colors, 'green') * .7152) + (map-get($colors, 'blue') * .0722);
}

@each $bp, $num in $grid-breakpoints {
    @each $color, $value in $theme-colors {
        $dark-mode-color: $value;
        @if luminance($value) > 0.5 {
            //$dark-mode-color: shift-color($value, 70%);
            $dark-mode-color: darken($value, 80%);
        }
        //.bg-#{$color} {
        //    background-color: $value !important;
        //}
        @include media-breakpoint-up($bp) {
            .bg-#{$bp}-#{$color} {
                background-color: $value !important;
            }
            .bg-#{$bp}-transparent {
                background-color: transparent !important;
            }
            .bg-#{$bp}-inherit {
                background-color: inherit !important;
            }
        }
        @include color-mode(dark) {
            //.bg-#{$color} {
            //    background-color: $dark-mode-color !important;
            //}
            @include media-breakpoint-up($bp) {
                .bg-#{$bp}-#{$color} {
                    background-color: $dark-mode-color !important;
                }
            }
        }
    }
}
