@for $i from 1 through 5 {
    .nt-#{$i} {
        @extend .position-relative;
        top: #{$i}px;
    }
    .nt--#{$i} {
        @extend .position-relative;
        top: -#{$i}px;
    }
    .nb-#{$i} {
        @extend .position-relative;
        bottom: #{$i}px;
    }
    .nb--#{$i} {
        @extend .position-relative;
        bottom: -#{$i}px;
    }
    .ns-#{$i} {
        @extend .position-relative;
        left: #{$i}px;
    }
    .ns--#{$i} {
        @extend .position-relative;
        left: -#{$i}px;
    }
    .ne-#{$i} {
        @extend .position-relative;
        right: #{$i}px;
    }
    .ne--#{$i} {
        @extend .position-relative;
        right: -#{$i}px;
    }
}
