@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark.scss";

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.15) !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(255, 255, 255, 0.075) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.175) !important;
}

.shadow-noon {
    box-shadow: 0 0 0.5rem 0 rgba(255, 255, 255, 0.1);
}

.shadow-noon-sm {
    box-shadow: 0 0 0.125rem 0 rgba(255, 255, 255, 0.1);
}

.shadow-noon-lg {
    box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0.1);
}

.shadow-inset {
    box-shadow: inset 0 0 10px 0 rgba(255, 255, 255, 0.1);
}

.shadow-inset-sm {
    box-shadow: inset 0 0 5px 0 rgba(255, 255, 255, 0.1);
}

.shadow-inset-lg {
    box-shadow: inset 0 0 1rem 0 rgba(255, 255, 255, 0.1);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
a,
.text-primary {
    color: fade-out($white, .2) !important;
}

.text-slightly-muted {
    color: $gray-600;
}

.form-switch .form-check-input:not(:checked) {
    --bs-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23003540'/%3E%3C/svg%3E");
}

.form-check-input,
.form-check-input:checked,
.form-switch .form-check-input,
.form-switch .form-check-input:checked {
    @extend .bg-dark;
    border-color: lighten($dark, 20%) !important;
}

.filter-label {
    color: $gray-400;
    background-color: #2c3034;

    &.active {
        background-color: #495365;
    }

    &:hover {
        background-color: #3b4046;
    }
}

.bg-body,
.bg-details {
    background-color: lighten($dark, 10%) !important;
}

.bg-light {
    background-color: fade-out($white, .9) !important;
    @extend .text-white;
}

.bg-opacity-5 {
    --bs-bg-opacity: 0.25;
    filter: invert(25%);
}

.bg-primary-hover:hover,
.bg-light-hover:hover,
.btn-light-hover:hover {
    background-color: fade-out($white, .9) !important;
}

.btn-light {
    background-color: fade-out($white, .9) !important;
    @extend .text-light;
    border: 1px solid fade-out($white, .9) !important;

    &:hover {
        background-color: fade-out($white, .8) !important;
    }
}

.btn-primary {
    background-color: lighten($primary, 10%) !important;
}

.btn-secondary {
    color: darken($secondary, 50%) !important;
}

.btn-outline-primary {
    @extend .border-light;
    @extend .text-white;

    &:hover {
        background-color: fade-out($white, .75) !important;
    }
}

.btn-group-bg {
    background-color: fade-out($white, .9) !important;
}

.dot-not-connected {
    background-color: darken($white, 40%) !important;
}

.tag {
    background-color: rgba(197, 221, 232, 0.18);
}

.tag:hover {
    background-color: rgba(197, 221, 232, 0.28);
}


.form-control,
.form-select,
.variable-form-control,
.variable-selector,
.variable-select-arrow,
.variable-form-select {
    @extend .text-white;
    mix-blend-mode: difference;
    background-color: fade-out($dark, .7) !important;

    &:focus, &:active, &.active {
        background-color: lighten($dark, .7) !important;
        border-color: lighten($dark, 40%) !important;
    }

    &:hover:not(:focus) {
        background-color: fade-out($dark, .9) !important;
    }
}

.variable-form-select,
.variable-select-arrow,
.variable-select-arrow-sm {
    background-image: url($arrow-white);
    border-color: fade-out($white, .6) !important;

    &:hover,
    &:active,
    &:focus {
        background-image: url($arrow-white);
    }
}

.variable-radio-control {
    &:not(.label-checked) {
        @extend .bg-dark;
        @extend .border-transparent;
    }

    &.label-checked {
        @extend .bg-light;
        @extend .border-light;
    }
}

.bg-white {
    @extend .bg-dark;
    @extend .text-white;
}

.tooltip-container {
    @extend .bg-dark;
    @extend .text-white;
    border-color: fade-out($white, .5) !important;
}

.dropdown-item {
    @extend .bg-dark;
    @extend .bg-dark-hover;
    @extend .text-white;

    &:active, &.active, &:hover {
        background-color: fade-out($white, .9) !important;
        @extend .border-light;
    }
}

.border-primary {
    border-color: #006471 !important;
}

.item-list {
    @extend .bg-dark;
    color: fade-out($white, .2) !important;
}

.gradient-text {
    &:after {
        background: linear-gradient(180deg, rgba(33, 37, 41, 0), rgba(33, 37, 41, 1));
    }
}
